import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";
import Banner from "../components/banner/banner";
import {
  mainBlack,
  mainBlue,
  whileInVew,
  screen,
} from "../components/variables/variables";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import CtaGradient from "../components/cta-gradient/cta-gradient";
import { GoTriangleLeft } from "react-icons/go";
import SocialSharing from "../components/insights/social-sharing";
import Author from "../components/insights/author";
import RelatedAside from "../components/insights/related-aside";
import formatDate from "../components/utils/format-date";
import { toSlug } from "../components/utils/helpers";

const Wrapper = styled.div`
  .main {
    padding: 30px 0 0 0;

    .flex-row {
      display: flex;
      position: relative;
      flex-wrap: wrap;

      .col {
        &--one {
          min-width: 50px;
          margin: 0 0 0 -25px;
          @media ${screen.xsmall} {
            min-width: 100px;
            margin: 0;
          }
        }

        &--two {
          width: calc(100% - 25px);
          padding: 0 0 0 6px;
          @media ${screen.xsmall} {
            width: calc(100% - 100px);
          }
          @media ${screen.small} {
            padding: 0 70px 0 0;
            width: calc(77% - 100px);
          }
          @media ${screen.medium} {
            padding: 0 70px 0 0;
          }
          @media ${screen.large} {
            padding: 0 90px 0 0;
          }

          .heading {
            &--article {
              margin: 0 0 14px 0;
            }
          }

          .back-link {
            color: ${mainBlack};
            display: flex;
            align-items: center;
            margin: 0 0 30px 0;
            max-width: max-content;
            @media ${screen.xsmall} {
              &:hover {
                color: ${mainBlue};
              }
            }

            svg {
              font-size: 1.025rem;
              margin: 0 6px 0 0;
              @media ${screen.xsmall} {
                font-size: 1.3rem;
                margin: 0 9px 0 0;
              }
            }

            span {
              font-size: 0.875rem;
              font-weight: 600;
              @media ${screen.xsmall} {
                font-size: 1.075rem;
              }
            }
          }

          .subheading {
            font-weight: 300;
            font-size: 0.925rem;
            @media ${screen.xsmall} {
              font-size: 1.1rem;
            }
          }

          .description {
            &--article {
              margin: 60px 0 0 0;

              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                margin: 32px 0 16px 0;
                @media ${screen.xsmall} {
                  margin: 42px 0 21px 0;
                }
              }

              h1 {
                font-size: 1.4rem;
                @media ${screen.xsmall} {
                  font-size: 2.4rem;
                }
              }

              h2 {
                font-size: 1.35rem;
                @media ${screen.xsmall} {
                  font-size: 2.2rem;
                }
              }
              h3 {
                font-size: 1.3rem;
                @media ${screen.xsmall} {
                  font-size: 2rem;
                }
              }
              h4 {
                font-size: 1.25rem;
                @media ${screen.xsmall} {
                  font-size: 1.8rem;
                }
              }
              h5 {
                font-size: 1.2rem;
                @media ${screen.xsmall} {
                  font-size: 1.6rem;
                }
              }
              h6 {
                font-size: 1.15rem;
                @media ${screen.xsmall} {
                  font-size: 1.4rem;
                }
              }

              ul,
              ol {
                margin: 40px 0 40px 40px;
              }

              li {
                font-size: 0.85rem;
                font-weight: 300;
                margin: 0 0 16px 0;
                @media ${screen.xsmall} {
                  font-size: 1rem;
                  margin: 0 0 21px 0;
                }
              }

              ul li {
                list-style-type: disc;
              }

              ol li {
                list-style-type: decimal;
              }

              a {
                color: ${mainBlack};
                text-decoration: underline;
              }

              div {
                position: relative;
                width: 100%;
                padding-top: 56.25%;
                margin: 20px 0;
                @media ${screen.xsmall} {
                  margin: 30px 0;
                }
              }

              iframe {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
              }

              .block-img {
                max-width: 600px;
                margin: 20px 0;
                @media ${screen.xsmall} {
                  margin: 30px 0;
                }

                img {
                  display: block;
                  width: 100%;
                }
              }
            }
          }
        }

        &--three {
          width: 100%;
          margin: 60px 0 0 0;
          @media ${screen.xsmall} {
            margin: 90px 0 0 0;
          }
          @media ${screen.small} {
            width: 23%;
            margin: 62px 0 0 0;
          }
        }
      }
    }
  }

  .author {
  }

  .cta {
    padding: 45px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }
  }
`;

const defaultCta = {
  cta_heading: {
    text: "Make Contact",
  },
  cta_description: {
    html: `<p>For a confidential discussion about the hurdles on your track toward big data analytics and digital transformation, including how we can help you prevent your business falling behind the competition, please give Sanjiv a call on <strong><a href="tel:1300247232" target="undefined" rel="noopener noreferrer">1300 247 232</a></strong> or <strong><a href="mailto:info@irada.com.au" target="undefined" rel="noopener noreferrer">info@irada.com.au</a></strong></p>`,
  },
  cta_button_label: "GET IN TOUCH",
};

const SingleInsight = ({ data }) => {
  const {
    title,
    featured_image,
    description,
    edit_published_date,
    author,
    cta_heading,
    cta_description,
    cta_button_label,
    related_insight_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const shareURL = `https://www.irada.au/insights/${toSlug(title.text)}`;

  const { first_publication_date } = data.content;

  const { about_tile_heading, about_tile_description } = data.aboutIrada.data;

  const date = edit_published_date
    ? formatDate(edit_published_date)
    : formatDate(first_publication_date);

  return (
    <Layout>
      <SEO
        title={title_tag}
        meta_description={meta_description}
        image={featured_image?.thumbnails?.og.url}
      />
      <Wrapper>
        <Banner banner_background_image={featured_image} noOverlay />
        <InnerWrapper>
          <motion.div className="main" {...whileInVew}>
            <div className="flex-row">
              <aside className="col col--one">
                <SocialSharing shareURL={shareURL} />
              </aside>
              <article className="col col--two">
                <Link className="back-link" to="/insights/">
                  <GoTriangleLeft />
                  <span> BACK TO INSIGHTS</span>
                </Link>
                <h1 className="heading heading--article">{title.text}</h1>
                <span className="subheading">
                  {author?.document?.data.full_name.text}{" "}
                  {author?.document?.data && <span className="pipe">|</span>}{" "}
                  {date}
                </span>
                <div
                  className="description description--article"
                  dangerouslySetInnerHTML={{ __html: description.html }}
                />
              </article>
              <aside className="col col--three">
                <RelatedAside
                  items={related_insight_list}
                  aboutHeading={about_tile_heading.text}
                  aboutDescription={about_tile_description.html}
                />
              </aside>
            </div>
          </motion.div>
        </InnerWrapper>

        <motion.section className="author" {...whileInVew}>
          {author?.document?.data && (
            <Author
              name={author?.document?.data.full_name.text}
              description={author?.document?.data.description.html}
              linkedin_account_url={author?.document?.data.linkedin_account_url}
              twitter_account_url={author?.document?.data.twitter_account_url}
              alt={author?.document?.data.image.alt}
              image={
                author?.document?.data.image.thumbnails.cropped.gatsbyImageData
              }
            />
          )}
        </motion.section>
        <section className="cta">
          <CtaGradient
            cta_heading={
              cta_heading.text ? cta_heading : defaultCta.cta_heading
            }
            cta_description={
              cta_description.html
                ? cta_description
                : defaultCta.cta_description
            }
            cta_button_label={
              cta_button_label ? cta_button_label : defaultCta.cta_button_label
            }
            relative
          />
        </section>
      </Wrapper>
    </Layout>
  );
};

export default SingleInsight;

export const dataQuery = graphql`
  query ($id: String!) {
    content: prismicInsight(id: { eq: $id }) {
      first_publication_date
      data {
        title {
          text
        }
        featured_image {
          alt
          url
          thumbnails {
            og {
              url
            }
          }
        }
        edit_published_date
        description {
          html
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                description {
                  html
                }
                full_name {
                  text
                }
                image {
                  alt
                  thumbnails {
                    cropped {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
                linkedin_account_url
                twitter_account_url
              }
            }
          }
        }
        cta_heading {
          text
        }
        cta_description {
          html
        }
        cta_button_label
        title_tag
        meta_description
        related_insight_list {
          insight {
            document {
              ... on PrismicInsight {
                id
                data {
                  featured_image {
                    alt
                    thumbnails {
                      cropped {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    aboutIrada: prismicInsightsPage {
      data {
        about_tile_heading {
          text
        }
        about_tile_description {
          html
        }
      }
    }
  }
`;
