import React from "react";
import styled from "styled-components";
import {
  mainBlack,
  mainWhite,
  transHover,
  screen,
} from "../variables/variables";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

const Wrapper = styled.div`
  position: sticky;
  top: 75px;
  box-shadow: 0 3px 14px rgba(30, 30, 30, 0.15);
  max-width: max-content;
  border-radius: 8px;
  background-color: ${mainWhite};
  z-index: 5;
  overflow: hidden;
  @media ${screen.xsmall} {
    top: 110px;
  }

  .react-share__ShareButton {
    display: block;
    width: 42px;
    height: 42px;
    transition: ${transHover};
    @media ${screen.xsmall} {
      width: 56px;
      height: 56px;
    }

    svg {
      font-size: 1rem;
      @media ${screen.xsmall} {
        font-size: 1.2rem;
      }
    }

    &:nth-child(2) {
      border-bottom: 1px solid #f1f0f0 !important;
      border-top: 1px solid #f1f0f0 !important;
    }

    @media ${screen.withCursor} {
      &:hover {
        background-color: ${mainBlack} !important;
        color: ${mainWhite} !important;
      }
    }
  }
`;

const SocialSharing = ({ shareURL }) => {
  return (
    <Wrapper>
      <FacebookShareButton url={shareURL} children={<FaFacebookF />} />
      <TwitterShareButton url={shareURL} children={<FaTwitter />} />
      <LinkedinShareButton url={shareURL} children={<FaLinkedinIn />} />
    </Wrapper>
  );
};

export default SocialSharing;
