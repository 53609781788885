import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  mainBlack,
  mainWhite,
  transHover,
  screen,
} from "../variables/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { toSlug } from "../utils/helpers";

const Wrapper = styled.div`
  .item-list {
    @media ${screen.xsmall} {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    @media ${screen.small} {
      display: block;
      margin: 0;
    }

    &__each {
      border-radius: 12px;
      box-shadow: 0 3px 14px rgba(30, 30, 30, 0.15);
      padding: 26px;
      margin: 0 0 26px 0;
      color: ${mainBlack};
      display: block;
      overflow: hidden;
      @media ${screen.xsmall} {
        padding: 36px;
        margin: 0 15px 30px 15px;
        width: calc(50% - 30px);
      }
      @media ${screen.small} {
        padding: 26px;
        margin: 0 0 60px 0;
        width: auto;
      }
      @media ${screen.medium} {
        padding: 32px;
      }

      .img-wrap {
        border-radius: 9px;
        overflow: hidden;
      }

      img {
        transition: ${transHover};
        display: block;
        width: 100%;
      }

      .subheading {
        font-weight: 700;
        font-size: 1rem;
        margin: 17px 0 10px 0;
        line-height: 1.45;
        text-transform: uppercase;
        @media ${screen.xsmall} {
          font-size: 1.1rem;
          margin: 22px 0 10px 0;
        }
      }

      button {
        border: 1px ${mainBlack} solid;
        background-color: transparent;
        padding: 7px 16px;
        border-radius: 8px;
        color: ${mainBlack};
        height: auto;
        margin: 10px 0 0 0;
        color: ${mainBlack};
        line-height: 30px;
        cursor: pointer;
        font-weight: 500;
        transition: ${transHover};
      }

      @media ${screen.withCursor} {
        &:hover img {
          transform: scale(1.125);
        }

        &:hover button {
          background: ${mainBlack};
          border: 1px ${mainBlack} solid;
          color: ${mainWhite};
        }
      }

      &--about-tile {
        background-color: ${mainBlack};
        color: ${mainWhite};

        .subheading {
          margin: 0 0 10px 0;
        }
      }
    }
  }
`;

const RelatedAside = ({ items, aboutHeading, aboutDescription }) => {
  const isRelatedInsight = !!items.length && !!items[0].insight.document;

  return (
    <Wrapper>
      <div className="item-list" role="listbox">
        {isRelatedInsight &&
          items.map((item, i) => (
            <Link
              to={`/insights/${toSlug(item.insight.document?.data?.title?.text)}`}
              key={(item.insight.document?.id || 0) + i}
              className="item-list__each"
            >
              <div className="img-wrap">
                <GatsbyImage
                  alt={
                    item.insight.document?.data.featured_image.alt || `Thumbnail`
                  }
                  image={
                    item.insight.document?.data.featured_image.thumbnails.cropped
                      .gatsbyImageData
                  }
                />
              </div>

              <h3 className="subheading">
                {item.insight.document?.data.title.text}
              </h3>

              <button>READ MORE</button>
            </Link>
          ))}

        <div className="item-list__each item-list__each--about-tile">
          <h3 className="subheading">{aboutHeading}</h3>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: aboutDescription }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default RelatedAside;
