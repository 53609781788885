import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { mainBlack, mainBlue, screen } from "../variables/variables";
import InnerWrapper from "../inner-wrapper/inner-wrapper";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";

const Wrapper = styled.div`
  margin: 30px 0 0 0;
  @media ${screen.xsmall} {
    margin: 90px 0 0 0;
  }

  .flex-row {
    border-top: 1px solid ${mainBlack};
    padding: 20px 0 0 0;
    @media ${screen.xsmall} {
      padding: 45px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .col {
      .subheading {
        font-size: 0.875rem;
        font-weight: 600;
        @media ${screen.xsmall} {
          font-size: 1.075rem;
        }
      }

      &--details {
        width: 100%;
        max-width: 800px;

        @media ${screen.xsmall} {
          padding: 0 70px 0 0;
        }

        .subrow {
          display: flex;
          @media ${screen.xsmall} {
          }

          .name {
            font-size: 0.85rem;
            font-weight: 400;
            margin: 4px 0 8px 0;
            @media ${screen.xsmall} {
              font-size: 1rem;
              margin: 6px 0 12px 0;
            }
          }

          .img-wrap {
            min-width: 80px;
            margin: 0 20px 0 0;
            @media ${screen.xsmall} {
              min-width: 128px;
              margin: 0 28px 0 0;
            }
          }

          .description {
            &--author {
              p {
                font-size: 0.75rem;
                font-weight: 300;
                margin: 0 0 10px 0;
                @media ${screen.xsmall} {
                  font-size: 0.85rem;
                  margin: 0 0 12px 0;
                }
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      &--social {
        padding: 16px 0 0 100px;
        @media ${screen.xsmall} {
          padding: 0;
        }

        .social-list {
          margin: 11px 0 0 0;
          @media ${screen.xsmall} {
            margin: 15px 0 0 0;
          }

          &__each {
            font-size: 1.4rem;
            margin: 0 8px 0 0;
            color: ${mainBlack};
            @media ${screen.xsmall} {
              font-size: 1.6rem;
              margin: 0 11px 0 0;
            }
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainBlue};
              }
            }
          }
        }
      }
    }
  }
`;

const Author = ({
  name,
  description,
  image,
  alt,
  linkedin_account_url,
  twitter_account_url,
}) => {
  const isSocialLink = !!twitter_account_url || !!linkedin_account_url;
  return (
    <Wrapper>
      <InnerWrapper>
        <div className="flex-row">
          <div className="col col--details">
            <div className="subrow">
              <div className="img-wrap">
                <GatsbyImage image={image} alt={alt || name} />
              </div>
              <div>
                <span className="subheading">ABOUT THE AUTHOR</span>
                <h6 className="name">{name}</h6>
                <div
                  className="description description--author"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </div>
          <div className="col col--social">
            {isSocialLink && <span className="subheading">FOLLOW ME</span>}

            <div className="social-list">
              {twitter_account_url && (
                <a
                  className="social-list__each"
                  href={twitter_account_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitterSquare />
                </a>
              )}

              {linkedin_account_url && (
                <a
                  className="social-list__each"
                  href={linkedin_account_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              )}
            </div>
          </div>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Author;
